<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>

      <v-row justify="center">

        <v-col cols="12" lg="9">

          <FormValidation ref="formValidation"
                          :data.sync="campaign"
                          @loading="[isLoading=$event,btnLoading=$event]"
                          module="campaigns"
                          path="info-planning"/>

          <CreateProgress :title="$t('campaign.title.key1')"
                          :progress="0"/>

          <v-card class="rounded-lg shadow"
                  flat
                  :disabled="isLoading"
                  :loading="isLoading">
            <v-card-text>
              <v-switch hide-details
                        :label="$t('field.isActive')"
                        v-model="campaign.isActive"/>
              <v-row>
                <v-col v-show="$func.langNC('fr')">
                  <v-text-field :label="$t('field.name')"
                                :hint="$t('hint.fr')"
                                persistent-hint
                                class="required"
                                v-model="campaign.name.fr">
                  </v-text-field>
                </v-col>
                <v-col v-show="$func.langNC('en')">
                  <v-text-field :label="$t('field.name')"
                                :hint="$t('hint.en')"
                                persistent-hint
                                class="required"
                                v-model="campaign.name.en"/>
                </v-col>
                <v-col v-show="$func.langNC('ar')">
                  <v-text-field :label="$t('field.name')"
                                :hint="$t('hint.ar')"
                                persistent-hint
                                class="required"
                                v-model="campaign.name.ar"/>
                </v-col>
              </v-row>

              <UploadFile type="image"
                          @loading="isLoading=$event"
                          @file="campaign.image = $event"
                          :path.sync="campaign.image"/>

              <DateRange :dates.sync="campaign.dates"
                         text-info='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut porta dolor'
                         @dates="campaign.dates = $event"/>

              <v-row>
                <v-col v-show="$func.langNC('fr')">
                  <v-textarea rows="1"
                              :label="$t('field.description')"
                              :hint="$t('hint.fr')"
                              persistent-hint
                              v-model="campaign.description.fr"/>
                </v-col>
                <v-col v-show="$func.langNC('en')">
                  <v-textarea rows="1"
                              :label="$t('field.description')"
                              :hint="$t('hint.en')"
                              persistent-hint
                              v-model="campaign.description.en"/>
                </v-col>
                <v-col v-show="$func.langNC('ar')">
                  <v-textarea rows="1"
                              :label="$t('field.description')"
                              :hint="$t('hint.ar')"
                              persistent-hint
                              v-model="campaign.description.ar"/>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>

          <v-row class="mt-1">
            <v-col>
              <v-btn text
                     color="primary"
                     depressed
                     to="/">
                <v-icon left>{{ $lang === 'ar' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
                {{ $t('btn.back') }}
              </v-btn>
            </v-col>
            <v-spacer/>
            <v-col :class="'text-'+$float">
              <v-btn color="primary"
                     :disabled="isLoading"
                     :loading="btnLoading"
                     @click="next"
                     type="submit"
                     depressed>
                {{ $t('btn.next') }}
                <v-icon right v-html="'mdi-arrow-'+$float"></v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      btnLoading: false,
    }
  },
  methods: {
    async next() {
      if (await this.$refs.formValidation.handleValidate()) {
        await this.$store.commit('campaign/toggelStepp1', 1)
        await this.$router.push('/campaigns/create/investigations')
      }
    }
  },
  computed: {
    campaign() {
      return this.$store.state.campaign.campaign
    },
    breadcrumbs() {
      return [
        {to: '/campaigns', text: 'breadcrumb.campaigns'},
        {text: 'breadcrumb.create', disabled: true},
        {text: 'breadcrumb.infoPlanning', disabled: false},
        {text: 'breadcrumb.investigations', disabled: true},
        {text: 'breadcrumb.tasks', disabled: true},
      ]
    },
  }
}
</script>

<style scoped>
</style>